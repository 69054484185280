import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { Container, Row, Col } from "react-bootstrap"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import bgVideo from "../../static/video/bgvideo-1.mp4"

// install Swiper modules
SwiperCore.use([Pagination, Navigation])

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <video
      className="bg-video"
      loop
      muted
      autoPlay
    >
      <source src={bgVideo} type="video/mp4" />
    </video>
    <Container className="landing">
      <Row>
        <Col xs={12} md={6}>
          <div style={{ padding: "0 30px", position: "relative" }}>
            <p className="subtitle">FLY AWAY (feat. Bobby John)</p>
            <h1>NEW SINGLE RELEASED</h1>
            <div className="wrap">
              <span>
                <a href="https://youtu.be/U1XenjQOitw" target="_blank">
                  <StaticImage
                    src="../images/Youtube-Music-badge.png"
                    alt="Fly Away Youtube"
                    placeholder="blurred"
                    width={130}
                  />
                </a>
              </span>
              <span>
                <a href="https://open.spotify.com/album/3g20vBhLDzjOrBDwgKLBMz" target="_blank">
                  <StaticImage
                    src="../images/spotify-badge.png"
                    alt="Fly Away Spotify"
                    placeholder="blurred"
                    width={140}
                  />
                </a>
              </span>
              <span>
                <a href="https://music.apple.com/us/album/fly-away-feat-bobby-john-single/1593424781?uo=4&app=apple+music" target="_blank">
                  <StaticImage
                    src="../images/apple-badge-2.png"
                    alt="Fly Away Apple Music"
                    placeholder="blurred"
                    width={110}
                  />
                </a>
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div style={{ padding: "0 30px", position: "relative" }}>
            <StaticImage
              src="../images/Stereopoetica_cover_.png"
              alt="Fly Away Album Cover"
              placeholder="blurred"
              width={350}
            />

            <div className="center">
              <p>NEW</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
